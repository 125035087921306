<template>
  <quote-body
    ref="quote"
    key="mainquote"
    @checking-out-old-version="(b) => (checkingOutOldVersion = b)"
    @changes="(ch) => (changes = ch)"
    @is-dirty="(b) => (isDirty = b)"
    @loading="loading = 1"
    @done="loading = 0"
  />
</template>

<script>
import QuoteBody from '../../bodies/Quote.vue'
import PageMixin from '../../mixins/Page'

export default {
  mixins: [PageMixin],
  data() {
    return {
      loading: 0,
      isDirty: 0,
      changes: {},
      checkingOutOldVersion: 0
    }
  },
  created() {
    this.clearStore()
  },
  methods: {
    /**
     * Clear the store so that we are efficient with meory
     */
    clearStore() {
      this.$store.commit({
        type: 'Quote/CLEAR'
      })
    },
    getChanges() {},
    cancelClick() {
      this.$refs.quote.reset()
    },
    saveClick() {
      this.$refs.quote.save()
    }
  },

  components: { QuoteBody }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
